import * as React from "react";
import styled from "styled-components";
import { Header, TechnologiesUsed, Footer, Image } from "../components";

const TSheetProjectStyles = styled.div`
  p,
  ul {
    color: ${(props) => props.theme.text.paragraph};
  }
  .project-description {
    width: 66.6667%;
    margin: 0 auto 80px;
  }
  h1 {
    text-align: center;
  }
  .project-description p {
    text-align: left;
    color: ${(props) => props.theme.text.paragraph};
  }
  .about-the-project-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }
  .client-images-container {
    width: 100%;
    margin-top: 50px;
  }
  .project-images-container {
    width: 100%;
  }
  .client-image-wrapper {
    margin-bottom: 111px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .client-description-container {
    width: 100%;
    margin: auto;
    padding: 0 17px;
  }
  .client-description-quote {
    border-left: 1px solid #ddd;
    padding-left: 1.618em;
    margin: 0;
  }
  .clients-words-container {
    margin-top: 60px;
  }
  .project-image-container {
    width: 100%;
  }
  .project-image-wrapper {
    padding: 17px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (max-width: 640px) {
    .about-the-project-container {
      display: block;
    }
    .client-image-wrapper {
      display: flex;
      justify-content: center;
    }
    .project-image-wrapper {
      display: flex;
      justify-content: center;
    }
    .client-description-container {
      width: 92%;
    }
  }
`;

const gridItems = [
  { link: "", imageName: "angular", text: "angular" },
  { link: "", imageName: "bootstrap", text: "bootstrap" },
  { link: "", imageName: "html5", text: "html5" },
  { link: "", imageName: "lessImage", text: "less" },
  { link: "", imageName: "chromeImage", text: "chrome" },
  { link: "", imageName: "gruntImage", text: "grunt" },
  { link: "", imageName: "githubImage", text: "github" },
  { link: "", imageName: "pivotalTrackerImage", text: "pivotal tracker" },
  { link: "", imageName: "googleAnalyticsImage", text: "google analytics" },
];

const bulletItems = [
  { bullet: "Angular", subBullets: [] },
  { bullet: "BootStrap", subBullets: [] },
  { bullet: "HTML5", subBullets: [] },
  { bullet: "LESS/CSS", subBullets: [] },
  { bullet: "IndexedDB", subBullets: [] },
  { bullet: "TSheets API", subBullets: [] },
  { bullet: "OAuth2", subBullets: [] },
  { bullet: "Chrome Packaged App", subBullets: [] },
  { bullet: "Grunt", subBullets: [] },
  { bullet: "Github", subBullets: [] },
  { bullet: "Pivotal Tracker", subBullets: [] },
  { bullet: "Google Analytics", subBullets: [] },
];

const TSheetsChromeAppPage = () => (
  <main>
    <Header />
    <TSheetProjectStyles>
      <div className="project-description">
        <h1>TSheets Time Tracking </h1>
        <h1> Chrome App</h1>
        <p>
          <span>
            <a href="https://www.tsheets.com/">TSheets</a>
          </span>
          &nbsp;needed to create a product offering in the desktop app space and
          was evaluating the Chrome Web Store as a new marketing channel.
          TSheets also wanted feedback from an outside developer about using its
          public API to develop a web application, which faces similar software
          design challenges as its mobile apps.
        </p>
        <p>
          With these goals in mind, we created a Chrome Packaged App for
          TSheets.
        </p>
        <p>
          <a href="https://chrome.google.com/webstore/detail/tsheets-time-tracking/nnocjcjlgfdmkhaiojlhgimllmkaeinh">
            Live Project Link (Chrome Web Store)
          </a>
        </p>
      </div>
      <TechnologiesUsed
        gridItems={gridItems}
        bulletItems={bulletItems}
        columnCount={3}
      />
      <div className="about-the-project-container">
        <div className="client-images-container">
          <div className="client-image-wrapper">
            <a href="https://www.tsheets.com/">
              <Image imageName="tsheets" alt="TSheets" />
            </a>
          </div>
          <div className="client-image-wrapper">
            <Image
              imageName="tsheetsTimeTrackingImage"
              alt="TSheets Time Tracking"
            />
          </div>
        </div>
        <div className="client-description-container">
          <h3>About the Client (TSheets)</h3>
          <p>
            <a href="/">
              <span>TSheets</span>
            </a>{" "}
            is an innovative SaaS company and one of the best places to work in
            Idaho (yes, there actually was a vote, and they won). TSheets has
            been able to create industry-shifting technology and products
            because of the many smart, talented people who work there.
          </p>
          <div className="client-description-quote">
            <p>
              “It has been such a pleasure working with and learning from a
              company that knows how to create software the right way.” — Jeremy
              Zerr, Founder, Zerrtech
            </p>
          </div>
          <div className="clients-words-container">
            <h3>In the Client&apos;s Words</h3>
            <div className="client-description-quote">
              <p>
                “After a handful of contracting mishaps with other vendors in
                the past, Zerrtech is now the only contractor we will work with.
                Their attention to detail and thorough weekly updates go above
                and beyond anything we have ever received. Add to that detailed
                invoices and instant communication whenever I want to reach out
                makes them an invaluable resource. Not that we needed yet
                another reason, but Jeremy’s commitment as a volunteer to
                improving the Boise tech ecosystem aligns perfectly with the
                TSheets engineering team values. We wouldn’t use anyone else.” —
                TSheets, VP of Engineering,{" "}
                <span>
                  <a href="https://www.linkedin.com/in/j-d-mullin-39187695">
                    J.D. Mullin
                  </a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="about-the-project-container">
        <div className="project-images-container">
          <div className="project-image-wrapper">
            <Image
              imageName="project-role-img-1"
              alt="TSheets Project Role 1"
            />
          </div>
          <div className="project-image-wrapper">
            <Image
              imageName="project-role-img-2"
              alt="TSheets Project Role 2"
            />
          </div>
          <div className="project-image-wrapper">
            <Image
              imageName="project-role-img-3"
              alt="TSheets Project Role 3"
            />
          </div>
        </div>
        <div className="client-description-container">
          <h3>Project Role</h3>
          <p>
            Our roles in this project included:
            <ul>
              <li>Software Architecture and Design</li>
              <li>Coding all HTML, CSS and Javascript</li>
              <li>
                Implementing a design spec provided by the TSheets UX design
                team
              </li>
            </ul>
            We managed day-to-day project management using Pivotal Tracker,
            planning weekly sprints and communicating progress to TSheets every
            two weeks with a formal check in and progress report.
          </p>
          <h3>Feature Summary</h3>
          <p>
            Here is a high-level feature summary of what TSheets can offer its
            customers through the Chrome App:
            <ul>
              <li>View current clocked-in time card</li>
              <li>Add, edit or delete existing timesheets</li>
              <li>Work offline, syncing with the TSheets API</li>
              <li>
                Stay in sync with other TSheets platforms, including the web app
                and Android and iOS apps
              </li>
              <li>Provide a means for users to directly submit feedback</li>
            </ul>
            Although this Chrome App is used as a desktop app, it is designed
            with the same technology at the core of web app development: HTML,
            CSS and Javascript. The differences include:
            <ul>
              <li>
                Using the Chrome API to enable features that are specific to
                Chrome apps .
              </li>
              <li>
                Using separate background and foreground processes that
                communicate with each other (similar to designing native mobile
                apps). This adds more complexity than what typical web app would
                have, but increases flexibility by enabling offline
                functionality and the capability to run as a standalone desktop
                app.
              </li>
            </ul>
          </p>
          <h3>Technology Details</h3>
          <p>
            We chose Angular as our front-end Javascript framework for this
            project. There were some examples on the Chrome App developer site
            regarding Angular, which is capable of running in Content Security
            Policy (CSP) mode that enables compatability with the security
            restrictions for a Chrome Packaged App. With our ample experience
            using Angular to design front-end applications, this was a
            straightforward process.
          </p>
          <p>
            Creating a Chrome App presents unique challenges. To run offline,
            all files need to be available all the time, which means they must
            be distributed with your code, including fonts, images, icons and
            third-party libraries.
          </p>
          <p>
            Another challenge is that there must be both a background and
            foreground process. All API communication and sync-related
            operations must reside in the background so they can continue to
            work even if the app is minimized or idle. Changes are communicated
            by passing messages to the foreground as there is no shared memory
            between the two.
          </p>
          <p>
            We used a couple of technologies to create the app’s sync
            functionality. We utilized IndexedDB to query and store data locally
            and efficiently. We then used Angular ngResource and
            transformers/interceptors to put data in proper format for the API,
            to process errors, and to handle OAuth2 token-related management.
          </p>
          <p>
            The application distributes analytics to Google Analytics, and users
            can send feedback directly to TSheets using a form that sends also
            sends debug data to help fix potential issues.
          </p>
        </div>
      </div>
    </TSheetProjectStyles>
    <Footer />
  </main>
);

export default TSheetsChromeAppPage;
